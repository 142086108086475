/*
// Animation frame helper
const requestAnimFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60);
  };

// Initialize page animations
async function initializeAnimations() {
  try {
    const appContainer = document.querySelector('.app');
    if (!appContainer) return;

    // Interactive gradient animation
    const interactive = document.querySelector('.interactive');
    let mouseX = 0;
    let mouseY = 0;

    document.addEventListener('mousemove', e => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    });

    function animateGradient() {
      if (interactive) {
        interactive.style.transform = `translate(${mouseX / 50}px, ${mouseY / 50}px)`;
      }
      requestAnimFrame(animateGradient);
    }
    console.log('animate mouse??!');
    // Start animation
    animateGradient();

    // Initial page load animation
    await delay(100);
    appContainer.classList.add('loaded');

    // AI message animation
    await delay(500);
    const aiMsg = document.querySelector('.ai-msg');
    if (aiMsg) {
      aiMsg.classList.add('show');
    }
  } catch (error) {
    console.error('Animation initialization error:', error);
  }
}

// Animation frame helper
const requestAnimFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60);
  };

// Initialize page animations
function initializeAnimations() {
  const appContainer = document.querySelector('.app');
  if (!appContainer) return;

  // Interactive gradient animation
  const interBubble = document.querySelector('.interactive');
  let curX = 0;
  let curY = 0;
  let tgX = 0;
  let tgY = 0;

  const move = () => {
    curX += (tgX - curX) / 20;
    curY += (tgY - curY) / 20;
    interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
    requestAnimFrame(move);
  };

  window.addEventListener('mousemove', event => {
    tgX = event.clientX;
    tgY = event.clientY;
  });

  move();

  // Initial page load animation
  setTimeout(() => {
    appContainer.classList.add('loaded');
  }, 100);

  // AI message animation
  setTimeout(() => {
    const aiMsg = document.querySelector('.ai-msg');
    if (aiMsg) {
      aiMsg.classList.add('show');
    }
  }, 500);
}

// Ensure animations are initialized after DOM is fully loaded
document.addEventListener('DOMContentLoaded', initializeAnimations);
*/

// Helper function for delays
const delay = ms => new Promise(resolve => window.setTimeout(resolve, ms));

// Add required elements
const appContainer = document.querySelector('.app');
const yearSpan = document.querySelector('.curyear');
const interBubble = document.querySelector('.interactive');
const aiMsg = document.querySelector('.ai-msg');

// Animation frame helper
const requestAnimFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60);
  };

// Update dynamic elements
function updateElementValues() {
  // Update curent year
  if (yearSpan) {
    yearSpan.textContent = new Date().getFullYear();
  }
}

// Initialize page animations
async function initializeAnimations() {
  if (!appContainer) return;

  // Interactive gradient animation
  let curX = 0;
  let curY = 0;
  let tgX = 0;
  let tgY = 0;

  // Mouse follower (move interactive bubble)
  const initMouseFollower = () => {
    curX += (tgX - curX) / 20;
    curY += (tgY - curY) / 20;
    interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
    requestAnimFrame(initMouseFollower);
  };

  // Initial page load animation
  await delay(100);
  appContainer.classList.add('loaded');

  // AI message animation
  await delay(500);
  if (aiMsg) {
    aiMsg.classList.add('show');
  }

  // Mouse move event listener
  window.addEventListener('mousemove', event => {
    tgX = event.clientX;
    tgY = event.clientY;
  });

  // Initialize other functions
  updateElementValues();
  initMouseFollower();
}

// Ensure animations are initialized after DOM is fully loaded
document.addEventListener('DOMContentLoaded', initializeAnimations);
